import React from 'react';
//import {AnimatePresence} from 'framer-motion';



import 'styles/index.scss';


export const wrapPageElement = ({element}) => (
  <>
    {element}
  </>
);
